import React, { useState, useEffect } from "react";
import {
  Box,
  Fab,
  Badge,
  Snackbar,
  Alert,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import {
  Chat as ChatIcon,
  LocalOffer as OfferIcon,
  Event as EventIcon,
  DirectionsCar as CarIcon,
  Home as PropertyIcon,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  collection,
  query,
  where,
  onSnapshot,
  serverTimestamp,
} from "firebase/firestore";
import { useSubscriptionContext } from "./SubscriptionContext";

const PLAN_LIMITS = {
  Básico: { offers: 3, events: 2, trips: 1, cars: 1 },
  Profesional: { offers: 10, events: 5, trips: 3, cars: 3 },
  Premium: { offers: Infinity, events: 15, trips: 10, cars: 10 },
};

const PUBLICATION_TYPE_MAP = {
  "/publishOffer": "offers",
  "/publishEvents": "events",
  "/publishCar": "cars",
  "/publishProperty": "trips",
};

const ACTIONS = [
  { icon: <OfferIcon />, name: "Ofertas", path: "/publishOffer" },
  { icon: <EventIcon />, name: "Eventos", path: "/publishEvents" },
  { icon: <CarIcon />, name: "Autos", path: "/publishCar" },
  { icon: <PropertyIcon />, name: "Propiedades", path: "/publishProperty" },
];

const FloatingMenu = () => {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [publicationCounts, setPublicationCounts] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore();
  const { subscription, loading } = useSubscriptionContext();

  const currentPlan = subscription?.planName || null;
  const isSubscriptionActive = subscription?.status === "ACTIVE";

  useEffect(() => {
    const fetchUserDataAndCounts = async () => {
      const user = auth.currentUser;
      if (!user) return;

      const now = new Date();
      const monthYear = `${now.getMonth() + 1}-${now.getFullYear()}`;
      const countsRef = doc(
        db,
        "publicationCounts",
        `${user.uid}_${monthYear}`
      );
      const countsSnap = await getDoc(countsRef);

      if (countsSnap.exists()) {
        setPublicationCounts(countsSnap.data());
      } else {
        const initialCounts = {
          offers: 0,
          events: 0,
          trips: 0,
          cars: 0,
          timestamp: serverTimestamp(),
        };
        await setDoc(countsRef, initialCounts);
        setPublicationCounts(initialCounts);
      }
    };

    if (!loading) {
      fetchUserDataAndCounts();
    }
  }, [auth.currentUser, db, loading]);

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) return;

    const conversationsRef = collection(db, "conversations");
    const q = query(
      conversationsRef,
      where("participants", "array-contains", user.uid)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      let totalUnread = 0;
      snapshot.docs.forEach((doc) => {
        const conversationData = doc.data();
        totalUnread += conversationData.unreadCount?.[user.uid] || 0;
      });
      setUnreadMessageCount(totalUnread);
    });

    return () => unsubscribe();
  }, [auth.currentUser, db]);

  const handlePublishClick = async (path) => {
    if (!isSubscriptionActive) {
      setOpenModal(true);
      return;
    }

    if (!checkPublicationLimit(path)) {
      setAlertMessage(
        `Has alcanzado el límite de publicaciones mensuales para tu plan.`
      );
      setShowAlert(true);
      return;
    }

    await incrementPublicationCount(PUBLICATION_TYPE_MAP[path]);
    navigate(path);
  };

  const checkPublicationLimit = (path) => {
    if (subscription?.status !== 'ACTIVE') return false;
  
    const type = PUBLICATION_TYPE_MAP[path];
    const currentCount = publicationCounts[type] || 0;
    const limit = PLAN_LIMITS[currentPlan]?.[type] ?? 0; 
    return currentCount < limit;
  };

  const incrementPublicationCount = async (type) => {
    const user = auth.currentUser;
    if (!user) return;

    const now = new Date();
    const monthYear = `${now.getMonth() + 1}-${now.getFullYear()}`;
    const countsRef = doc(db, "publicationCounts", `${user.uid}_${monthYear}`);

    const newCounts = {
      ...publicationCounts,
      [type]: (publicationCounts[type] || 0) + 1,
      timestamp: serverTimestamp(),
    };

    await setDoc(countsRef, newCounts);
    setPublicationCounts(newCounts);
  };

  const getModalMessage = () => {
    if (!subscription?.status) {
      return "Para publicar contenido, necesitas tener una suscripción activa. ¿Te gustaría suscribirte ahora?";
    }

    switch (subscription.status) {
      case "EXPIRED":
        return "Tu suscripción ha expirado por falta de pago. Por favor, renueva tu suscripción para continuar publicando.";
      case "CANCELLED":
        return "Tu suscripción ha sido cancelada. ¿Te gustaría reactivar tu suscripción?";
      case "NONE":
        return "Para publicar contenido, necesitas tener una suscripción activa. ¿Te gustaría suscribirte ahora?";
      default:
        return "Tu suscripción no está activa. ¿Te gustaría activar una suscripción?";
    }
  };

  const getModalTitle = () => {
    if (!subscription?.status) {
      return "Suscripción Requerida";
    }

    switch (subscription.status) {
      case "EXPIRED":
        return "Suscripción Expirada";
      case "CANCELLED":
        return "Suscripción Cancelada";
      case "NONE":
        return "Suscripción Requerida";
      default:
        return "Suscripción No Activa";
    }
  };

  if (loading) {
    return null; // Or a loading spinner
  }

  return (
    <Box sx={{ position: "fixed", bottom: 100, right: 16, zIndex: 1000 }}>
      <Box sx={{ position: "relative", mt: 3, height: 320 }}>
        <SpeedDial
          ariaLabel="Menu de publicación"
          sx={{ position: "absolute", bottom: 0, right: 0 }}
          icon={<SpeedDialIcon />}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
        >
          {ACTIONS.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() => handlePublishClick(action.path)}
            />
          ))}
        </SpeedDial>
      </Box>

      <Box sx={{ position: "fixed", bottom: 100, right: 96 }}>
        <Badge
          badgeContent={unreadMessageCount}
          color="error"
          invisible={unreadMessageCount === 0}
        >
          <Fab
            color="secondary"
            aria-label="chats"
            component={Link}
            to="/chats"
            size="medium"
          >
            <ChatIcon />
          </Fab>
        </Badge>
      </Box>

      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>{getModalTitle()}</DialogTitle>
        <DialogContent>
          <Typography>{getModalMessage()}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)}>Cancelar</Button>
          <Button
            component={Link}
            to="/subscription"
            onClick={() => setOpenModal(false)}
            color="primary"
            variant="contained"
          >
            {subscription?.status === "EXPIRED"
              ? "Renovar Suscripción"
              : "Ir a Suscripciones"}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showAlert}
        autoHideDuration={6000}
        onClose={() => setShowAlert(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowAlert(false)}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default FloatingMenu;
