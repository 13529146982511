import React, { useState, useEffect } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import {
  getDoc,
  doc,
  serverTimestamp,
  runTransaction,
} from "firebase/firestore";
import { db } from "../firebase";
import { isLoggedIn } from "../session";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  Container,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import { AppBar, Toolbar, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function CheckOut() {
  const [isContracted, setIsContracted] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [itemDetails, setItemDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { type, itemId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const percentageParam = searchParams.get("percentage");
  const reservationPercentage = Number(percentageParam) || 100;

  const COMMISSION_RATE = 0.05;
  const PLATFORM_PAYPAL_EMAIL = process.env.REACT_APP_PLATFORM_PAYPAL_EMAIL;

  const handleBack = () => {
    navigate(-1);
  };

  const calculatePrices = (basePrice, isQR = false) => {
    const numericPrice = Number(basePrice) || 0;

    if (isQR) {
      const commission = numericPrice * COMMISSION_RATE;
      const total = numericPrice + commission;
      return {
        basePrice: numericPrice.toFixed(2),
        commission: commission.toFixed(2),
        total: total.toFixed(2),
        fullPrice: numericPrice.toFixed(2),
        percentagePaid: 100,
        remainingAmount: "0.00",
      };
    }

    const adjustedBasePrice = numericPrice * (reservationPercentage / 100);
    const commission = adjustedBasePrice * COMMISSION_RATE;
    const total = adjustedBasePrice + commission;

    return {
      basePrice: adjustedBasePrice.toFixed(2),
      commission: commission.toFixed(2),
      total: total.toFixed(2),
      fullPrice: numericPrice.toFixed(2),
      percentagePaid: reservationPercentage,
      remainingAmount: (numericPrice - adjustedBasePrice).toFixed(2),
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await isLoggedIn();
        if (!user) {
          navigate("/login");
          return;
        }

        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (!userDoc.exists()) {
          throw new Error("User document not found");
        }

        setCurrentUser({
          ...user,
          displayName: userDoc.data().displayName,
          profileImageUrl: userDoc.data().profileImageUrl,
        });

        // Handle QR payment data from location state
        if (location.state?.couponData) {
          const qrData = location.state.couponData;
          setItemDetails({
            type: 'qr',
            price: qrData.totalAmount,
            title: qrData.storeName,
            offers: qrData.offers,
            ownerPaypalEmail: qrData.paypalEmail || PLATFORM_PAYPAL_EMAIL,
            uid: qrData.storeId,
          });
          setLoading(false);
          return;
        }

        // Existing car/trip payment logic
        const itemCollectionName = type === "auto" ? "cars" : "places";
        const itemRef = doc(db, itemCollectionName, itemId);
        const itemDoc = await getDoc(itemRef);

        if (!itemDoc.exists()) {
          throw new Error("Item not found");
        }

        const itemData = itemDoc.data();
        const itemPrice = Number(itemData.price) || 0;

        const ownerRef = doc(db, "users", itemData.uid);
        const ownerDoc = await getDoc(ownerRef);

        if (!ownerDoc.exists()) {
          throw new Error("Item owner details not found");
        }

        setItemDetails({
          ...itemData,
          price: itemPrice,
          ownerPaypalEmail: ownerDoc.data().paypalEmail,
        });

        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, type, itemId, location.state]);

  const handleQRPayment = async (orderId) => {
    if (!currentUser || !itemDetails) return;

    try {
      await runTransaction(db, async (transaction) => {
        const paymentDocRef = doc(db, "payments", orderId);
        
        const prices = calculatePrices(itemDetails.price, true);

        transaction.set(paymentDocRef, {
          uid: currentUser.uid,
          storeId: itemDetails.uid,
          storeName: itemDetails.title,
          amount: parseFloat(prices.basePrice),
          commission: parseFloat(prices.commission),
          total: parseFloat(prices.total),
          offers: itemDetails.offers,
          paypalOrderId: orderId,
          createdAt: serverTimestamp(),
          type: 'qr_payment',
        });

        // Save commission record
        const commissionDocRef = doc(db, "commissions", orderId);
        transaction.set(commissionDocRef, {
          orderId,
          storeId: itemDetails.uid,
          buyerId: currentUser.uid,
          baseAmount: parseFloat(prices.basePrice),
          commissionAmount: parseFloat(prices.commission),
          type: 'qr_payment',
          createdAt: serverTimestamp(),
        });
      });

      setIsContracted(true);
      navigate("/home");

      setTimeout(() => {
        alert("¡Pago completado exitosamente! Serás redirigido a tus pagos.");
      }, 1000);
    } catch (error) {
      console.error("Error in payment processing:", error);
      setError(error.message);
    }
  };

  const handleContract = async (orderId) => {
    if (itemDetails.type === 'qr') {
      return handleQRPayment(orderId);
    }

    // Existing contract logic for cars/trips
    if (!currentUser || !itemId || !type) return;

    try {
      await runTransaction(db, async (transaction) => {
        const collectionName = type === "auto" ? "vehiculos" : "trips";
        const contractDocRef = doc(
          db,
          collectionName,
          `${currentUser.uid}_${itemId}`
        );

        const contractDoc = await transaction.get(contractDocRef);
        if (contractDoc.exists()) {
          throw new Error("Contract already exists");
        }

        const prices = calculatePrices(itemDetails.price);

        transaction.set(contractDocRef, {
          uid: currentUser.uid,
          sellerId: itemDetails.uid,
          [`${type}Id`]: itemId,
          basePrice: parseFloat(prices.fullPrice),
          paidAmount: parseFloat(prices.basePrice),
          commission: parseFloat(prices.commission),
          totalPrice: parseFloat(prices.total),
          percentagePaid: prices.percentagePaid,
          remainingAmount: parseFloat(prices.remainingAmount),
          paymentCompleted: prices.percentagePaid === 100,
          paypalOrderId: orderId,
          createdAt: serverTimestamp(),
          itemType: type,
          nextPaymentDue:
            prices.percentagePaid < 100
              ? new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
              : null,
        });

        const itemCollectionName = type === "auto" ? "cars" : "places";
        const itemDocRef = doc(db, itemCollectionName, itemId);
        const itemStatus = {
          vendido: prices.percentagePaid === 100,
          reservado: prices.percentagePaid < 100,
          buyerId: currentUser.uid,
          soldAt: serverTimestamp(),
          percentagePaid: prices.percentagePaid,
          remainingAmount: parseFloat(prices.remainingAmount),
        };
        transaction.update(itemDocRef, itemStatus);

        const commissionDocRef = doc(db, "commissions", orderId);
        transaction.set(commissionDocRef, {
          orderId,
          itemId,
          itemType: type,
          sellerId: itemDetails.uid,
          buyerId: currentUser.uid,
          basePrice: parseFloat(prices.basePrice),
          commissionAmount: parseFloat(prices.commission),
          percentagePaid: prices.percentagePaid,
          createdAt: serverTimestamp(),
        });
      });

      setIsContracted(true);

      if (type === "auto") {
        navigate("/vehiculos");
      } else if (type === "viaje") {
        navigate("/trips");
      }

      setTimeout(() => {
        const message =
          reservationPercentage === 100
            ? `¡Felicidades por tu ${
                type === "auto" ? "nueva compra" : "reserva"
              }! Pago completado al 100%.`
            : `¡${
                type === "auto" ? "Vehículo reservado" : "Viaje reservado"
              } con un ${reservationPercentage}% de pago inicial!`;

        alert(
          `${message} Serás redirigido a tus ${
            type === "auto" ? "vehículos" : "viajes"
          }.`
        );
      }, 1000);
    } catch (error) {
      console.error("Error in contract creation:", error);
      setError(error.message);
    }
  };

  const createOrder = (data, actions) => {
    if (!itemDetails || typeof itemDetails.price !== "number") {
      throw new Error("Invalid item price");
    }

    const prices = calculatePrices(itemDetails.price, itemDetails.type === 'qr');

    return actions.order.create({
      purchase_units: [
        {
          reference_id: `payment_${itemDetails.type === 'qr' ? 'qr' : itemId}`,
          amount: {
            currency_code: "USD",
            value: prices.basePrice,
          },
          payee: {
            email_address: itemDetails.ownerPaypalEmail,
          },
          description: itemDetails.type === 'qr' 
            ? `Pago en ${itemDetails.title}`
            : `Pago principal por ${type === "auto" ? "auto" : "viaje"}: ${itemDetails.title || itemDetails.description}`,
        },
        {
          reference_id: `commission_${itemDetails.type === 'qr' ? 'qr' : itemId}`,
          amount: {
            currency_code: "USD",
            value: prices.commission,
          },
          payee: {
            email_address: PLATFORM_PAYPAL_EMAIL,
          },
          description: `Comisión de servicio`,
        },
      ],
      application_context: {
        shipping_preference: "NO_SHIPPING",
      },
    });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      handleContract(details.id);
    });
  };

  if (loading) {
    return (
      <>
        <AppBar position="fixed" sx={{ bgcolor: "#312783" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleBack}
              aria-label="back"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Checkout
            </Typography>
          </Toolbar>
        </AppBar>
        <Container sx={{ mt: 10, textAlign: "center" }}>
          <CircularProgress />
        </Container>
      </>
    );
  }

  if (error) {
    return (
      <>
        <AppBar position="fixed" sx={{ bgcolor: "#312783" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleBack}
              aria-label="back"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Error
            </Typography>
          </Toolbar>
        </AppBar>
        <Container sx={{ mt: 10 }}>
          <Alert severity="error">{error}</Alert>
        </Container>
      </>
    );
  }

  if (!itemDetails || typeof itemDetails.price !== "number") {
    return (
      <>
        <AppBar position="fixed" sx={{ bgcolor: "#312783" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleBack}
              aria-label="back"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Error
            </Typography>
          </Toolbar>
        </AppBar>
        <Container sx={{ mt: 10 }}>
          <Alert severity="error">Error: Precio del ítem no válido</Alert>
        </Container>
      </>
    );
  }

  const prices = calculatePrices(itemDetails.price, itemDetails.type === 'qr');
  const isQRPayment = itemDetails.type === 'qr';

  return (
    <Box sx={{ pb: 7 }}>
      <AppBar position="fixed" sx={{ bgcolor: "#312783" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleBack}
            aria-label="back"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {isQRPayment
              ? "Pago QR"
              : type === "auto"
              ? reservationPercentage === 100
                ? "Comprar Auto"
                : "Reservar Auto"
              : "Reservar Viaje"}
          </Typography>
        </Toolbar>
      </AppBar>

      <Container maxWidth="sm" sx={{ mt: 10 }}>
        <Card>
          <CardContent>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6">
                {isQRPayment
                  ? "Detalles del pago:"
                  : reservationPercentage === 100
                  ? "Detalles de la compra:"
                  : "Detalles de la reserva:"}
              </Typography>
              
              {isQRPayment ? (
                <>
                <Typography>
                    Comercio: {itemDetails.title}
                  </Typography>
                  
                  {itemDetails.offers?.map((offer, index) => (
                    <Card key={index} sx={{ mt: 2, bgcolor: "grey.50" }}>
                      <CardContent>
                        <Typography variant="subtitle1">{offer.product}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          {offer.description}
                        </Typography>
                        <Box
                          sx={{
                            mt: 1,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="body2" color="text.secondary">
                            Precio original: ${parseFloat(offer.price).toFixed(2)}
                          </Typography>
                          {offer.offer > 0 && (
                            <Typography variant="body2" color="success.main">
                              {offer.offer}% OFF
                            </Typography>
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  ))}
                </>
              ) : (
                <Typography>
                  {type === "auto" ? "Auto:" : "Destino:"}{" "}
                  {itemDetails.title || itemDetails.place}
                </Typography>
              )}

              <Divider sx={{ my: 2 }} />

              <Typography>Precio total: ${prices.fullPrice}</Typography>
              {!isQRPayment && (
                <Typography>
                  {type === "auto"
                    ? "Porcentaje de pago inicial"
                    : "Porcentaje de reserva"}
                  : {prices.percentagePaid}%
                </Typography>
              )}
              <Typography>Monto a pagar ahora: ${prices.basePrice}</Typography>
              <Typography>
                Comisión de servicio: ${prices.commission}
              </Typography>
              <Typography variant="h6" sx={{ mt: 1 }}>
                Total a pagar ahora: ${prices.total}
              </Typography>

              {!isQRPayment && prices.percentagePaid < 100 && (
                <Box
                  sx={{ mt: 2, p: 2, bgcolor: "info.light", borderRadius: 1 }}
                >
                  <Typography variant="subtitle1" color="info.contrastText">
                    Información de pago restante:
                  </Typography>
                  <Typography color="info.contrastText">
                    Monto pendiente: ${prices.remainingAmount}
                  </Typography>
                  <Typography color="info.contrastText">
                    Fecha límite de pago:{" "}
                    {new Date(
                      Date.now() + 30 * 24 * 60 * 60 * 1000
                    ).toLocaleDateString()}
                  </Typography>
                </Box>
              )}
            </Box>

            {!isContracted && (
              <Box sx={{ mt: 2, mb: 2 }}>
                <PayPalScriptProvider
                  options={{
                    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                  }}
                >
                  <PayPalButtons
                    createOrder={createOrder}
                    onApprove={onApprove}
                    style={{
                      layout: "vertical",
                      color: "blue",
                      shape: "rect",
                      label: "pay",
                      height: 45,
                    }}
                  />
                </PayPalScriptProvider>
              </Box>
            )}

            {isContracted && (
              <Alert severity="success">
                {isQRPayment
                  ? "¡Pago realizado con éxito!"
                  : type === "auto"
                  ? "¡Felicidades por tu nueva compra!"
                  : "¡Tu reserva ha sido confirmada!"}
              </Alert>
            )}
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}

export default CheckOut;