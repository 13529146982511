import React, { useState, useEffect } from "react";
import {
  Container,
  CardMedia,
  Box,
  Typography,
  CircularProgress,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { doc, getDoc, setDoc, collection, serverTimestamp, query, getDocs, where } from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import Header3 from "./Header3";
import BottomNav from "./BottomNav";
import { isLoggedIn } from "../../session";

const VerPlace = () => {
  const { placeId } = useParams();
  const [place, setPlace] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [authUserId, setAuthUserId] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAuthUserId = async () => {
      const user = await isLoggedIn();
      if (user) {
        setAuthUserId(user.uid);
      } else {
        navigate("/login");
      }
    };
    fetchAuthUserId();
  }, [navigate]);

  useEffect(() => {
    const fetchPlace = async () => {
      try {
        const placeDocRef = doc(db, "places", placeId);
        const placeDoc = await getDoc(placeDocRef);

        if (placeDoc.exists()) {
          const placeData = placeDoc.data();
          setPlace({ id: placeDoc.id, ...placeData });
        } else {
          console.error("No se encontró el alojamiento.");
        }
      } catch (error) {
        console.error("Error al obtener el alojamiento:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlace();
  }, [placeId]);

  const formatDate = (timeToDo) => {
    if (timeToDo instanceof Date) {
      return timeToDo.toLocaleDateString();
    } else if (timeToDo && timeToDo.toDate instanceof Function) {
      return timeToDo.toDate().toLocaleDateString();
    }
    return "";
  };

  const handleOpenImageModal = () => {
    setOpenImageModal(true);
  };

  const handleCloseImageModal = () => {
    setOpenImageModal(false);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === place.images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? place.images.length - 1 : prevIndex - 1
    );
  };

  const handleChatClick = async () => {
    if (!authUserId || !place) {
      console.error("Usuario no autenticado o lugar no cargado");
      return;
    }
  
    try {
      const conversationsRef = collection(db, "conversations");
      
      // Buscar si ya existe una conversación para este viaje y estos usuarios
      const q = query(
        conversationsRef, 
        where("type", "==", "viaje"),
        where("itemId", "==", place.id),
        where("participants", "array-contains", authUserId)
      );
      const querySnapshot = await getDocs(q);

      let conversationId;
      if (querySnapshot.empty) {
        // Si no existe, crear una nueva conversación
        conversationId = [authUserId, place.uid, "viaje", place.id].sort().join("_");
        const conversationDocRef = doc(conversationsRef, conversationId);
        await setDoc(conversationDocRef, {
          participants: [authUserId, place.uid],
          type: "viaje",
          itemId: place.id,
          lastMessage: {
            content: "",
            timestamp: serverTimestamp(),
            senderId: authUserId
          },
          unreadCount: {
            [authUserId]: 0,
            [place.uid]: 0
          }
        });
      } else {
        // Si existe, usar el ID de la conversación existente
        conversationId = querySnapshot.docs[0].id;
      }
  
      // Navegar al chat unificado
      navigate(`/chatbox/viaje/${place.id}/${place.uid}`);
    } catch (error) {
      console.error("Error al iniciar la conversación:", error);
    }
  };

  const styles = {
    card: {
      padding: "20px",
    },
    title: {
      fontSize: "24px",
      fontWeight: "bold",
      marginBottom: "8px",
    },
    description: {
      fontSize: "14px",
      fontWeight: "bold",
      marginBottom: "8px",
    },
    price: {
      fontSize: "18px",
      fontWeight: "bold",
      color: "#4CAF50",
      marginBottom: "8px",
    },
    place: {
      fontSize: "14px",
      color: "dodgerblue",
      marginBottom: "8px",
    },
    created: {
      fontSize: "12px",
      color: "#999",
    },
    imageContainer: {
      position: "relative",
      width: "100%",
      height: "300px",
    },
    mainImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    additionalImagesOverlay: {
      position: "absolute",
      bottom: "10px",
      right: "10px",
      width: "80px",
      height: "80px",
      backgroundColor: "rgba(0,0,0,0.5)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      cursor: "pointer",
    },
    additionalImagesCount: {
      fontSize: "24px",
      fontWeight: "bold",
    },
    noImage: {
      width: "100%",
      height: "100px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#f0f0f0",
      color: "#666",
    },
    imageModal: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "fixed",
      top: 0,
      left: 0,
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      zIndex: 1000,
    },
    imageModalContent: {
      maxWidth: "80%",
      maxHeight: "80%",
      position: "relative",
      backgroundColor: "white",
      padding: "20px",
      borderRadius: "10px",
    },
    imageModalImage: {
      width: "100%",
      height: "auto",
      borderRadius: "10px",
    },
    imageModalNavigation: {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
  };

  if (loading) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (!place) {
    return (
      <Container>
        <Typography>No se encontró el alojamiento.</Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Header3 />
      <Container sx={{ mt: 10, mb: 20 }}>
        <Grid container spacing={2} sx={{ mb: 20 }}>
          <Grid item xs={12}>
            <Box style={styles.card}>
              <Typography style={styles.title}>{place.title}</Typography>
              <Typography style={styles.description}>
                Descripcion: <br />
                {place.description}
              </Typography>
              <Typography style={styles.price}>
                Precio por día: $ {place.price}
              </Typography>
              <Typography style={styles.place}>
                Ciudad: {place.city}
              </Typography>
              <Typography style={styles.place}>
                Lugar: {place.place}
              </Typography>
              <Typography style={styles.place}>
                Categoria: {place.category}
              </Typography>
              <Box style={styles.imageContainer}>
                {place.images && place.images.length > 0 ? (
                  <>
                    <CardMedia
                      component="img"
                      image={place.images[0]}
                      alt={`Imagen principal de ${place.name}`}
                      style={styles.mainImage}
                    />
                    {place.images.length > 1 && (
                      <Box
                        style={styles.additionalImagesOverlay}
                        onClick={handleOpenImageModal}
                      >
                        <Typography style={styles.additionalImagesCount}>
                          +{place.images.length - 1}
                        </Typography>
                        <Typography>Ver más</Typography>
                      </Box>
                    )}
                  </>
                ) : (
                  <Box style={styles.noImage}>
                    <Typography>No hay imágenes disponibles</Typography>
                  </Box>
                )}
              </Box>
              <Typography style={styles.created}>
                Fecha de publicación: {formatDate(place.createdAt)}
              </Typography>
              <Button
                variant="contained"
                sx={{ mt: 1 }}
                fullWidth
                color="primary"
                onClick={handleChatClick}
                disabled={authUserId === place.uid}
              >
                {authUserId === place.uid ? "Este es tu anuncio" : "Consultar"}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Dialog
          open={openImageModal}
          onClose={handleCloseImageModal}
          aria-labelledby="image-modal-title"
          aria-describedby="image-modal-description"
          fullScreen
        >
          <DialogTitle id="image-modal-title">
            Imágenes del Alojamiento
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="image-modal-description">
              {place && place.images && place.images.length > 1 && (
                <Box style={styles.imageModalContent}>
                  <CardMedia
                    component="img"
                    image={place.images[currentImageIndex]}
                    alt={`Imagen ${currentImageIndex + 1} del alojamiento`}
                    style={styles.imageModalImage}
                  />
                  <Box style={styles.imageModalNavigation}>
                    <IconButton onClick={handlePreviousImage}>
                      <ArrowBackIosIcon />
                    </IconButton>
                    <IconButton onClick={handleNextImage}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Box>
                </Box>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseImageModal}>Cerrar</Button>
          </DialogActions>
        </Dialog>
      </Container>
      <BottomNav />
    </Container>
  );
};

export default VerPlace;