import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Box,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import { auth, db, uploadImage, uploadVideo } from "../firebase";
import {
  doc,
  getDoc,
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import Atras from "./atras";
import { useSubscriptionContext } from "./SubscriptionContext";

const PublishEvent = () => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    date: null,
    images: [],
    videos: [],
    eventType: "", // Nuevo campo para el tipo de evento
  });
  const [loading, setLoading] = useState(false);
  const [publishSuccess, setPublishSuccess] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const { subscription } = useSubscriptionContext(); // Correct usage

  useEffect(() => {
    if (!subscription) {
      navigate('/subscription'); // Redirige si no hay suscripción activa
    }
  }, [subscription, navigate]);



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    // Limpiar el error del campo cuando se modifica
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
  
    if (!formData.title.trim()) {
      newErrors.title = "El título es obligatorio";
      isValid = false;
    }
    if (!formData.description.trim()) {
      newErrors.description = "La descripción es obligatoria";
      isValid = false;
    }
    if (!formData.date) {
      newErrors.date = "La fecha es obligatoria";
      isValid = false;
    }
    if (formData.images.length === 0 && formData.videos.length === 0) {
      newErrors.media = "Debes subir al menos una imagen o un video";
      isValid = false;
    }
    if (!formData.eventType) {
      newErrors.eventType = "Selecciona un tipo de evento";
      isValid = false;
    }
  
    setErrors(newErrors);
    return isValid;
  };
  

  const handlePublishEvents = async () => {
    if (!validateForm()) {
      return;
    }

    const user = auth.currentUser;

    if (!user) {
      console.error("No user is signed in");
      return;
    }

    setLoading(true);

    try {
      const { uid } = user;
      const userDocRef = doc(db, "users", uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        const {
          displayName: userName,
          profileImageUrl: imageProfile,
          uid: userId,
        } = userData;

        await addDoc(collection(db, "events"), {
          uid: userId,
          name: userName,
          image: imageProfile,
          title: formData.title,
          description: formData.description,
          date: formData.date,
          images: formData.images,
          videos: formData.videos,
          eventType: formData.eventType, // Guardar el tipo de evento
          createdAt: serverTimestamp(),
        });
        setPublishSuccess(true);
      } else {
        console.error("User document does not exist");
        setErrors({ general: "Error al obtener datos del usuario" });
      }
    } catch (error) {
      console.error("Error publishing event:", error);
      setErrors({ general: "Error al publicar el evento" });
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (newDate) => {
    setFormData({ ...formData, date: newDate });
    setErrors((prevErrors) => ({ ...prevErrors, date: "" }));
  };

  const handleImageUpload = async (event) => {
    const files = event.target.files;

    if (!files) return;

    setLoading(true);

    try {
      const imageUrls = [];

      for (const file of files) {
        const imageUrl = await uploadImage(file);
        imageUrls.push(imageUrl);
      }

      setFormData((prevFormData) => ({
        ...prevFormData,
        images: [...prevFormData.images, ...imageUrls],
      }));
    } catch (error) {
      console.error("Error uploading images:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleVideoUpload = async (event) => {
    const files = event.target.files;

    if (!files) return;

    setLoading(true);

    try {
      const videoUrls = [];

      for (const file of files) {
        const videoUrl = await uploadVideo(file);
        videoUrls.push(videoUrl);
      }

      setFormData((prevFormData) => ({
        ...prevFormData,
        videos: [...prevFormData.videos, ...videoUrls],
      }));
    } catch (error) {
      console.error("Error uploading videos:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveMedia = (type, index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [type]: prevFormData[type].filter((_, i) => i !== index),
    }));
  };

  const handleRedirectHome = () => {
    navigate("/events");
  };

  const styles = {
    appBar: {
      bgcolor: "#312783",
    },
    toolbar: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px",
    },
    container: {
      mt: 14,
    },
    uploadButton: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "50px",
      backgroundColor: "#f0f0f0",
      border: "2px solid #312783",
      marginTop: "20px",
      borderRadius: "8px",
      color: "black",
    },
    progress: {
      position: "absolute",
      color: "#312783",
    },
    publishButton: {
      mt: 3,
    },
    errorText: {
      color: "red",
      fontSize: "0.75rem",
      marginTop: "3px",
    },
    imagePreview: {
      width: "100px",
      height: "100px",
      objectFit: "cover",
      margin: "5px",
      border: "1px solid #ccc",
      borderRadius: "5px",
    },
    removeButton: {
      display: "block",
      color: "white",
      cursor: "pointer",
    },
  };

  return (
    <Container>
      <AppBar position="fixed" sx={{ bgcolor: "#312783" }}>
        <Toolbar
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Atras color="inherit" />
          <Container style={styles.user}>
            <Typography variant="h5" color="white" gutterBottom>
              Publicar Evento
            </Typography>
          </Container>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 15, mb: 20 }}>
        <TextField
          autoFocus
          margin="dense"
          id="title"
          label="Título del evento"
          type="text"
          fullWidth
          required
          name="title"
          value={formData.title}
          onChange={handleInputChange}
          error={!!errors.title}
          helperText={errors.title}
        />
        <TextField
          margin="dense"
          id="description"
          label="Descripción del evento"
          type="text"
          fullWidth
          required
          name="description"
          rows={4}
          inputProps={{
            maxLength: 350
          }}
          multiline
          value={formData.description}
          onChange={handleInputChange}
          error={!!errors.description}
          helperText={errors.description}
        />
        <Box mt={2} mb={1}>
          <Typography>Fecha del evento:</Typography>
          <DatePicker
            onChange={handleDateChange}
            value={formData.date}
            format="dd/MM/yyyy"
          />
          {errors.date && (
            <Typography sx={styles.errorText}>{errors.date}</Typography>
          )}
        </Box>

        <Typography>Tipo de evento:</Typography>
        <FormControl fullWidth margin="dense">
          <InputLabel id="eventType-label">Tipo de evento</InputLabel>
          <Select
            labelId="eventType-label"
            id="eventType"
            name="eventType"
            value={formData.eventType}
            onChange={handleInputChange}
            error={!!errors.eventType}
          >
            <MenuItem value="exposicion">Exposición</MenuItem>
            <MenuItem value="innauguracion">Innauguración</MenuItem>
            <MenuItem value="feriado">Feriado</MenuItem>
            <MenuItem value="concierto">Concierto</MenuItem>
            <MenuItem value="festival">Festival</MenuItem>
            <MenuItem value="obraDeTeatro">Obra de teatro</MenuItem>
            <MenuItem value="conferencia">Conferencia</MenuItem>
            <MenuItem value="otro">Otro</MenuItem>
          </Select>
          {errors.eventType && (
            <Typography sx={styles.errorText}>{errors.eventType}</Typography>
          )}
        </FormControl>
        <Typography>Subir Imagenes del evento:</Typography>
        <label htmlFor="upload-image-button" style={{ width: "100%" }}>
          <Button
            variant="contained"
            fullWidth
            component="div"
            sx={{ mt: 2, marginBottom: "20px" }}
            disabled={loading}
            required
          >
            <AddPhotoAlternateIcon sx={{ color: "black" }} />
            Subir Imágenes del producto
            {loading && <CircularProgress size={40} thickness={4} />}
          </Button>
        </label>
        <input
          type="file"
          accept="image/*"
          onClick={(event) => (event.target.value = null)}
          onChange={handleImageUpload}
          style={{ display: "none" }}
          id="upload-image-button"
          multiple
        />
        <Typography>Subir Video del evento:</Typography>
        <label htmlFor="upload-video-button" style={{ width: "100%" }}>
          <Button
            variant="contained"
            fullWidth
            component="div"
            sx={{ mt:2,marginBottom: "10px" }}
            disabled={loading}
            required
          >
            <VideoLibraryIcon sx={{ color: "black" }} />
            Subir Video del producto
            {loading && <CircularProgress size={40} thickness={4} />}
          </Button>
        </label>
        <input
          type="file"
          accept="video/*"
          onClick={(event) => (event.target.value = null)}
          onChange={handleVideoUpload}
          style={{ display: "none" }}
          id="upload-video-button"
          multiple
        />

        {errors.media && (
          <Typography color="error" variant="caption">
            {errors.media}
          </Typography>
        )}

        <Grid container sx={{ mt: 2 }}>
          {formData.images.map((imageUrl, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} margin={1}>
              <Box
                sx={
                  {
                    /* estilos */
                  }
                }
              >
                <img
                  src={imageUrl}
                  alt="Imagen del producto"
                  style={{ width: "100%", borderRadius: "5px" }}
                />
                <Button
                  variant="contained"
                  onClick={() => handleRemoveMedia("images", index)}
                >
                  ELIMINAR
                </Button>
              </Box>
            </Grid>
          ))}
          {formData.videos.map((videoUrl, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} margin={1}>
              <Box
                sx={
                  {
                    /* estilos */
                  }
                }
              >
                <video
                  src={videoUrl}
                  controls
                  style={{ width: "100%", borderRadius: "5px" }}
                />
                <Button
                  variant="contained"
                  onClick={() => handleRemoveMedia("videos", index)}
                >
                  ELIMINAR
                </Button>
              </Box>
            </Grid>
          ))}
        </Grid>
        {publishSuccess && (
          <Box sx={{ textAlign: "center", mt: 2 }}>
            <Typography>El evento se ha publicado correctamente.</Typography>
            <Button onClick={handleRedirectHome} color="primary">
              Ir a Eventos
            </Button>
          </Box>
        )}
        {!publishSuccess && (
          <Button
            variant="contained"
            sx={styles.publishButton}
            fullWidth
            onClick={handlePublishEvents}
            color="primary"
            disabled={loading}
          >
            {loading ? "Publicando..." : "Publicar"}
          </Button>
        )}
      </Container>
    </Container>
  );
};

export default PublishEvent;
